.wrapper {
  display: grid;

  /* grid-auto-rows: 100px; */

  }
  @media only screen and (min-width: 501px) {
    .wrapper {
      grid-template-columns: 1fr;
      /* grid-template-columns: repeat(2, 1fr); */
      /* justify-content: space-between; */
    }
  }
  @media only screen and (max-width: 500px) {
    .wrapper {
      grid-template-columns: 1fr;
      justify-content: space-between;
    }
  }

