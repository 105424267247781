.card {
  display: grid;
  grid-column: center-start / center-end;
  margin: 15rem 0;

  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  grid-gap: 6rem;
  align-items: start;
}

main {
  padding-top: 32px;
}

.package {
  width: 80%;
  margin: 16px 0;
  border: 4px solid #0e4f1f;
  border-left: none;
}

.package:hover,
.package:active {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  border-color: #ff5454;
  /* border-color: #ff5454 !important; */
}

.package a {
  text-decoration: none;
  color: inherit;
  display: block;
  padding: 32px;
}

.package__subtitle {
  color: #979797;
}

.package__info {
  padding: 16px;
  border: 1px solid #0e4f1f;
  font-size: 20px;
  color: #0e4f1f;
  background: white;
}

.clearfix {
  clear: both;
}

#plus {
  background: rgba(213, 255, 220, 0.95);
}

#free {
  background: rgba(234, 252, 237, 0.95);
  float: right;
  border-right: none;
  border-left: 4px solid #0e4f1f;
  text-align: right;
}

#free:hover,
#free:active {
  border-left-color: #ff5454;
}

#premium {
  background: rgba(14, 79, 31, 0.95);
}

#premium .package__title {
  color: white;
}

#premium .package__subtitle {
  color: #bbb;
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}
@media only screen and (max-width: 75em) {
  html {
    font-size: 50%;
  }
}

body {
  font-family: "Nunito", sans-serif;
  color: #6d5d4b;
  font-weight: 400;
  line-height: 1.6;
}

/* rendez-vous   */

.app__img--5 {
  width: 100%;
  height: 100%;
  grid-row: 1 / 7;
  grid-column: 1 / 7;
}

/************************Horaire*******************/

.app__text5 {
  font-size: 1.5rem;
  font-style: italic;
  color: black;
  margin-bottom: 0;
}

.app__day1,
.app__day2 {
  /* padding: 16px 24px; */
  /* padding-left: 6rem; */
  /* text-align: left; */
  /* width:0%; */
}

.app__day1 {
  width: 150px;
  text-align: left;
  margin: 0 0rem 0 6rem;

  padding-left: 1rem;
  padding-right: 1rem;
}

.app__day2 {
  padding-right: 1rem;
  padding-left: 1.2rem;

  /* border: 1px solid rgb(190, 190, 190); */
  /* text-align: right; */
}

.app__day5 {
  line-height: 2.5;
  font-size: 1.5rem;
  display: grid;
}

.app__day6 {
  /* width: 100px; */
  border-bottom: 0.2rem dotted #9acd32;
  /* border: 1px solid rgb(190, 190, 190); */
  /* display: grid; */
}

.app__day5,
.app__day6:nth-child(odd) {
  background-color: #f8f9fa;
}

.app__day5,
.app__day6:nth-child(even) {
  background-color: #e9ecef;
}

.app__img--5 {
  width: 100%;
  height: 100%;
  grid-row: 1 / 7;
  grid-column: 1 / 7;
}

/*CONTAINER*/
.container {
  display: grid;
  grid-template-rows: 80vh min-content 40vw repeat(3, min-content);
  grid-template-columns:
    [sidebar-start] 8rem [sidebar-end full-start] minmax(6rem, 1fr)
    [center-start] repeat(8, [col-start] minmax(min-content, 14rem) [col-end])
    [center-end] minmax(6rem, 1fr)
    [full-end];
}
@media only screen and (max-width: 100em) {
  .container {
    grid-template-rows: 6rem 80vh min-content 40vw repeat(3, min-content);
    grid-template-columns:
      [full-start] minmax(6rem, 1fr) [center-start] repeat(
        8,
        [col-start] minmax(min-content, 14rem) [col-end]
      )
      [center-end] minmax(6rem, 1fr) [full-end];
  }
}
@media only screen and (max-width: 62.5em) {
  .container {
    grid-template-rows: 6rem 80vh min-content 40vw repeat(3, min-content);
    grid-template-columns:
      [full-start] minmax(6rem, 1fr) [center-start] repeat(
        8,
        [col-start] minmax(min-content, 14rem) [col-end]
      )
      [center-end] minmax(6rem, 1fr) [full-end];
  }
}
@media only screen and (max-width: 50em) {
  .container {
    grid-template-rows: 6rem calc(100vh - 6rem);
  }
}

/*ICONS 1-6*/

.feature {
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-row-gap: 1.5rem;
  grid-column-gap: 2.5rem;
  margin-top: 10px;
}

.feature__text {
  font-size: 1.7rem;
}

.footer {
  background-color: #101d2c;
  grid-column: full-start / full-end;
  margin-top: 6rem;
  padding: 2rem;
}

.nav {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  grid-gap: 2rem;
  align-items: center;
}
/* .nav__logo {
  margin-top: 1rem;
  height: 8.2rem;
  width: 5.2rem;
  display: grid;
  align-items: center;
  padding-top: 0.2rem;
} */
.nav__link:link,
.nav__link:visited {
  font-size: 1.4rem;
  color: #fff;
  text-decoration: none;
  font-family: "Josefin Sans", sans-serif;
  text-transform: uppercase;
  text-align: center;
  padding: 1.5rem;
  display: block;
  transition: all 0.2s;
}
.nav__link:hover,
.nav__link:active {
  background-color: rgba(255, 255, 255, 0.05);
  transform: translateY(-3px);
}

.copyright {
  font-size: 1.4rem;
  color: #aaa;
  text-transform: uppercase;
  margin-top: 6rem;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  width: 70%;
  margin-bottom: 4rem;
}

/******************************/
/*FOOTER*/
/******************************/
.nav {
  display: flex;
  justify-content: space-between;
}
.nav__t1 {
  color: #9acd32;
  font-weight: bold;
  font-size: 2.5rem;
  display: block;
  line-height: 1;
  padding-left: 0;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

.nav__item {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.4rem;
}
.nav__icon {
  width: 2.6rem;
  height: 2.6rem;
}

.gallery {
  background-color: #bde0fe;
  grid-column: full-start / full-end;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(7, 5vw);
  grid-gap: 1.5rem;
  padding: 1.5rem;
}
.gallery__item--1 {
  grid-row: 1 / span 2;
  grid-column: 1 / span 2;
}
.gallery__item--2 {
  grid-row: 1 / span 3;
  grid-column: 3 / span 3;
}
.gallery__item--3 {
  grid-row: 1 / span 2;
  grid-column: 6 / 7;
}
.gallery__item--4 {
  grid-row: 1 / span 2;
  grid-column: 7 / -1;
}
.gallery__item--5 {
  grid-row: 3 / span 3;
  grid-column: 1 / span 2;
}
.gallery__item--6 {
  grid-row: 4 / span 2;
  grid-column: 3 / span 2;
}
.gallery__item--7 {
  grid-row: 4 / 5;
  grid-column: 5 / 6;
}
.gallery__item--8 {
  grid-row: 3 / span 2;
  grid-column: 6 / span 2;
}
.gallery__item--9 {
  grid-row: 3 / span 3;
  grid-column: 8 / -1;
}
.gallery__item--10 {
  grid-row: 6 / span 2;
  grid-column: 1 / 2;
}
.gallery__item--11 {
  grid-row: 6 / span 2;
  grid-column: 2 / span 2;
}
.gallery__item--12 {
  grid-row: 6 / span 2;
  grid-column: 4 / 5;
}
.gallery__item--13 {
  grid-row: 5 / span 3;
  grid-column: 5 / span 3;
}
.gallery__item--14 {
  grid-row: 6 / span 2;
  grid-column: 8 / -1;
}
.gallery__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

/*HEADER LOGO*/
.header {
  background-color: #54483a;
  grid-column: full-start / col-end 4;
  background-image: linear-gradient(
      rgba(16, 29, 44, 0.9),
      rgba(16, 29, 44, 0.9)
    ),
    url(../assets/images/clinic/cliniqueExterieur614x409.avif);
  background-size: cover;
  background-position: center;
  padding: 8rem;
  padding-top: 4rem;
  display: grid;
  grid-template-rows: 1fr min-content minmax(6rem, min-content) 1fr;
  grid-template-columns: minmax(min-content, max-content);
  grid-row-gap: 1.5rem;
  justify-content: center;
}
@media only screen and (max-width: 50em) {
  .header {
    grid-column: 1 / -1;
  }
}
@media only screen and (max-width: 37.5em) {
  .header {
    padding: 5rem;
  }
}
.header__logo {
  margin-top: 1em;
  height: 8rem;
  justify-self: center;
}
.header__btn {
  align-self: start;
  justify-self: start;
}

/* .header__seenon-logos {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 3rem;
  justify-content: center;
  align-items: center;
} */
.header__seenon-logos img {
  max-height: 110%;
  max-width: 1110%;
  /* filter: brightness(40%); */
}

/*DESCRIPTION OF EQUIPMENT*/
.homes {
  grid-column: center-start / center-end;
  margin: 15rem 0;
  display: grid;
  grid-template-columns: repeat(3, minmax(25rem, 1fr));
  grid-gap: 7rem;
}

.home {
  background-color: #f9f7f6;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 3.5rem;
}
.home__img {
  width: 100%;
  grid-row: 1 / 2;
  grid-column: 1 / -1;
  z-index: 1;
}
.home__like {
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  fill: #c69963;
  height: 2.5rem;
  width: 2.5rem;
  z-index: 2;
  justify-self: end;
  margin: 1rem;
}
.home__name {
  grid-row: 1 / 2;
  grid-column: 1 / -1;
  justify-self: center;
  align-self: end;
  z-index: 3;
  width: 80%;
  font-family: "Josefin Sans", sans-serif;
  font-size: 1.6rem;
  text-align: center;
  padding: 1.25rem;
  background-color: #101d2c;
  color: #fff;
  font-weight: 400;
  transform: translateY(50%);
}
.home__location,
.home__rooms {
  margin-top: 2.5rem;
}
.home__location,
.home__rooms,
.home__area,
.home__price {
  font-size: 1.5rem;
  margin-left: 2rem;
  display: flex;
  align-items: center;
}
.home__location svg,
.home__rooms svg,
.home__area svg,
.home__price svg {
  fill: #c69963;
  height: 2rem;
  width: 2rem;
  margin-right: 1rem;
}
.home__btn {
  grid-column: 1 / -1;
}

/*DMD*/
.csstors {
  background-color: #101d2c;
  grid-column: col-start 5 / full-end;
  padding: 3rem;
  display: grid;
  align-content: center;
  justify-content: center;
  justify-items: center;
  grid-row-gap: 2rem;
}
@media only screen and (max-width: 50em) {
  .csstors {
    grid-column: 1 / -1;
  }
}
.csstors__list {
  display: grid;
  grid-template-columns: min-content max-content min-content max-content;
  grid-column-gap: 2rem;
  grid-row-gap: 5vh;
  align-items: center;
}
@media only screen and (max-width: 50em) {
  .csstors__list {
    grid-template-columns: repeat(2, min-content max-content);
  }
}
@media only screen and (max-width: 37.5em) {
  .csstors__list {
    grid-template-columns: min-content max-content;
  }
}
.csstors__img {
  width: 6rem;
  border-radius: 50%;
  display: block;
}

.csstors__css {
  text-transform: uppercase;
  color: #aaa;
  margin-top: -3px;
}

/*SIDEBAR TOP*/
.sidebar {
  background-color: #c69963;
  grid-column: sidebar-start / sidebar-end;
  grid-row: 1 / -1;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 100em) {
  .sidebar {
    grid-column: 1 / -1;
    grid-row: 1 / 2;
    align-items: center;
    justify-content: space-between;
  }
}
@media only screen and (max-width: 62.5em) {
  .sidebar {
    grid-column: 1 / -1;
    grid-row: 1 / 2;
    align-items: center;
    justify-content: space-between;
  }
}

/*BUTTON*/
.nav-btn {
  border: none;
  border-radius: 0;
  background-color: #fff;
  height: 2px;
  width: 4.5rem;
  margin-top: 4rem;
}
.nav-btn::before,
.nav-btn::after {
  background-color: #fff;
  height: 2px;
  width: 4.5rem;
  content: "";
  display: block;
}
.nav-btn::before {
  transform: translateY(-1.5rem);
}
.nav-btn::after {
  transform: translateY(1.3rem);
}
@media only screen and (max-width: 100em) {
  .nav-btn {
    margin-top: 0;
    margin-right: 3rem;
  }
  .nav-btn::before {
    transform: translateY(-1.2rem);
  }
  .nav-btn::after {
    transform: translateY(1rem);
  }
}
@media only screen and (max-width: 62.5em) {
  .nav-btn {
    margin-top: 0;
    margin-right: 3rem;
  }
  .nav-btn::before {
    transform: translateY(-1.2rem);
  }
  .nav-btn::after {
    transform: translateY(1rem);
  }
}

/******************************************/
/* NAVIGATION*/
/******************************************/
.main-nav {
  display: flex;
}
.main-nav__list {
  list-style: none;
  display: flex;
  align-items: center;
  font-size: 2rem;
  gap: 3.2rem;
}
.main-nav__link {
  display: inline-block;
  text-decoration: none;
  color: white;
  font-weight: 300;
  transition: all 0.2s;
}
.main-nav__link--cta:link,
.main-nav__link--cta:visited {
  padding: 1.2rem 2.4rem;
  border-radius: 10px;
  color: #f9f7f6;
  background-color: #9acd32;
}
.main-nav__link--cta:hover,
.main-nav__link--cta:active {
  background-color: #101d2c;
}
.main-nav__link:link,
.main-nav__link:visited {
  transition: all 0.2s;
}
.main-nav__link:hover,
.main-nav__link:active {
  background-color: rgba(255, 255, 255, 0.5);
  transform: translateY(-3px);
}

.story__pictures {
  background-color: #c69963;
  grid-column: full-start / col-end 4;
  background-image: linear-gradient(
      rgba(198, 153, 99, 0.5),
      rgba(198, 153, 99, 0.5)
    ),
    url(../assets/images/clinic/couloir-2-1024x683.avif);
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  grid-template-columns: repeat(6, 1fr);
  align-items: center;
}
@media only screen and (max-width: 50em) {
  .story__pictures {
    grid-column: 1 / -1;
    padding: 6rem;
  }
}

.story__img--1 {
  width: 100%;
  grid-row: 2 / 6;
  grid-column: 2 / 6;
  box-shadow: 0 2rem 5rem rgba(0, 0, 0, 0.1);
}
@media only screen and (max-width: 50em) {
  .story__img--1 {
    grid-column: 1 / 5;
    grid-row: 1 / -1;
  }
}

.story__img--2 {
  width: 115%;
  grid-row: 4 / 6;
  grid-column: 4 / 7;
  z-index: 20;
  box-shadow: 0 2rem 5rem rgba(0, 0, 0, 0.2);
}
@media only screen and (max-width: 50em) {
  .story__img--2 {
    grid-row: 1 / -1;
    width: 100%;
  }
}

.story__content {
  background-color: #f9f7f6;
  grid-column: col-start 5 / full-end;
  padding: 6rem 8vw;
  /*
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    */
  display: grid;
  align-content: center;
  justify-items: start;
}
@media only screen and (max-width: 50em) {
  .story__content {
    grid-column: 1 / -1;
    grid-row: 5 / 6;
  }
}

.story__text {
  font-size: 1.5rem;
  font-style: italic;
  margin-bottom: 4rem;
}

/*TEAMS*/
.story1__pictures {
  background-color: #c69963;
  grid-column: full-start / col-end 4;
  background-image: linear-gradient(
    rgba(198, 153, 99, 0.5),
    rgba(198, 153, 99, 0.5)
  );
  margin-top: 6rem;
  background-size: cover;
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  grid-template-columns: repeat(6, 1fr);
}

.story1__img--1 {
  width: 120%;
  grid-row: 1 / 6;
  grid-column: 1 / 3;
  box-shadow: 0 2rem 5rem rgba(0, 0, 0, 0.1);
}

.story1__img--2 {
  width: 115%;
  grid-row: 2 / 6;
  grid-column: 3 / 5;
  z-index: 20;
  box-shadow: 0 2rem 5rem rgba(0, 0, 0, 0.2);
}

.story1__img--3 {
  width: 110%;
  grid-row: 4 / 7;
  grid-column: 5 / 7;
  z-index: 20;
  box-shadow: 0 2rem 5rem rgba(0, 0, 0, 0.2);
}

.story1__content {
  background-color: #f9f7f6;
  grid-column: col-start 5 / full-end;
  padding: 6rem 8vw;
  display: grid;
  align-content: center;
  justify-items: start;
  margin-top: 6rem;
}

.story1__text {
  font-size: 1.5rem;
  font-style: italic;
  margin-bottom: 4rem;
}
.story1__text img {
  width: 100%;
}
/* 
.heading-1,
.heading-2,
.heading-3,
.heading-4,
.heading-5,
.heading-6{
  font-family: "Josefin Sans", sans-serif;
  font-weight: 400;
}

.heading-1 {
  font-size: 4.5rem;
  color: #9acd32;
  line-height: 1;
  text-align: center;

}

.heading-2 {
  font-size: 4rem;
  font-style: italic;
  line-height: 1;
}
.heading-2--light {
  color: #f9f7f6;
}
.heading-2--dark {
  color: #54483a;
}

.heading-3 {
  font-size: 1.6rem;
  color: #c69963;

  text-transform: uppercase;
}

.heading-4 {
  font-size: 1.9rem;
}
.heading-4--light {
  color: #f9f7f6;
}
.heading-4--dark {
  color: #54483a;
}

.heading-5{
  color: #c69963;
}

.heading-6 {
  font-size: 1.6rem;
  color: #c69963;
  text-align: center;
  text-transform: uppercase;
  padding-top: 3px;
} */

/*BUTTON*/
.btn {
  display: inline-block;
  background-color: #c69963;
  color: #fff;
  border: none;
  border-radius: 0;
  font-family: "Josefin Sans", sans-serif;
  font-size: 1.5rem;
  text-transform: uppercase;
  padding: 1rem 3rem;
  cursor: pointer;
  transition: all 0.2s;
  /* width: auto; */
  height: 50px;
  /* margin-top: 18rem;
  margin-left: auto;
  margin-right: auto; */
}
.btn:hover {
  background-color: #7fcdff;
}

.mb-md {
  margin-bottom: 3rem;
}

.mb-lg {
  margin-bottom: 4rem;
}

.mb-hg {
  margin-bottom: 8rem;
}

/************************CONTACT US2*******************/
.app1__content1 {
  background-color: #f9f7f6;
  grid-column: full-start / col-end 4;
  padding: 6rem 8vw;
  display: grid;
  justify-content: center;
  justify-items: center;
  margin-top: 8rem;
  text-transform: uppercase;
}

.app1__text1 {
  font-size: 1.5rem;
  font-style: italic;
  margin-bottom: 4rem;
  text-transform: uppercase;
}

.app1__text1 *:focus {
  outline: none;
  box-shadow: 0 0 0 0.8rem rgba(253, 242, 233, 0.5);
}

.app1__cta-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 3.2rem;
  row-gap: 2.4rem;
}

.app1__label {
  display: block;
  font-size: 1.6rem;
  font-weight: 400;
  margin-bottom: 1rem;
  text-transform: uppercase;
  text-align: center;
}

.app1__input,
.app1__select {
  width: 200px;
  padding: 1.2rem;
  font-size: 1.2rem;
  font-family: inherit;
  color: inherit;
  border: none;
  background-color: #9acd32;
  border-radius: 9px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  color: white;
  display: block;
  margin: 1rem;
  height: 42px;
}
.app1__input_option {
  width: 200px;
  padding: 1.2rem;
  font-size: 1.2rem;
  font-family: inherit;
  color: inherit;
  border: none;
  background-color: #9acd32;
  border-radius: 9px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  color: white;
  display: block;
  margin: 1rem;
}

.app1__textarea {
  color: #023047;
  width: 100%;
  outline: none;

  /* margin-top: 1rem; */
}
/* @media only screen and (max-width: 768px) {
  .app1__textarea {
  }
} */

.app1__input::placeholder {
  color: #fff;
}

.app1__picture9 {
  background-color: #c69963;
  grid-column: col-end 4 / full-end;
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  grid-template-columns: repeat(6, 1fr);
  margin-top: 8rem;
}

.app1__img--9 {
  width: 100%;
  height: 100%;
  grid-row: 1 / 7;
  grid-column: 1 / 7;
}

.map-container {
  width: 100%;
  height: 60vh;
  padding: 6rem 8vw;
  margin-top: 8rem;
  justify-content: center;
  justify-items: center;
}

.valeur {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-column-gap: 1.5rem;
  align-items: center;
  font-size: 1.6rem;
  color: #fa8c10;
}

.logoSize {
  height: 100px;

  @media (max-width: 400px) {
    width: 110px;
    height: 100px;
  }
  @media (min-width: 401px) {
    width: 150px;
    height: 100px;
  }
  @media (min-width: 679px) {
    width: 197px;
    height: 100px;
  }
}
